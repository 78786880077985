<template>
  <div class="container vld-parent">
    <b-row>
      <loading
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>
      <b-card v-if="errorLoading">
        <b-alert variant="danger" show="true">
          <h4 class="alert-heading my-0">Message not found</h4>
        </b-alert>
        <p>There was an error loading the message.</p>
        <b-button variant="danger" size="sm" @click="loadImages"
          >Click here to try again</b-button
        >
      </b-card>

      <b-card class="w-100" v-if="message && !errorLoading">
        <b-row>
          <b-col>
            <b-card-title>Message Details</b-card-title>
          </b-col>
          <b-col>
            <b-button
              @click="goBackToMessages"
              variant="outline-primary"
              size="sm"
              class="float-md-right "
              >&lt; Back To All Messages</b-button
            ></b-col
          >
        </b-row>

        <b-row>
          <b-col lg="6" md="6" sm="12">
            <b>Category: </b>{{ message.category }}
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="6" md="6" sm="12">
            <b>Title: </b> {{ message.title }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <br />
            <b>Message:</b>

            <div v-html="message.message"></div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b>Sent To:</b>
            <b-table
              striped
              hover
              small
              :items="message.users"
              class="mt-2"
            ></b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/RepositoryFactory";
const MessagesRepository = RepositoryFactory.get("messagesRepository");

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: [],
  components: { Loading },
  data() {
    return {
      isLoading: false,
      errorLoading: false,
      selectedCategory: null,
      message: {
        title: "",
        message: "",
        messageCategoryId: null,
        users: []
      }
    };
  },
  async created() {},
  async mounted() {
    this.isLoading = true;
    this.errorLoading = false;

    try {
      this.message = await MessagesRepository.getById(this.messageId);
    } catch (error) {
      this.errorLoading = true;
    }

    this.isLoading = false;
  },
  methods: {
    goBackToMessages() {
      this.$router.push({
        name: "admin-messages"
      });
    }
  },
  computed: {
    messageId() {
      return this.$route.params.id;
    }
  },

  destroyed() {}
};
</script>

<style scoped></style>
